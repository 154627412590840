import { useEffect } from 'react';
import BlockScroller from "../BlockScroller";
import BlockHead from "../_BlockHead";
import ClickableLink from "../../../utilities/ClickableLink";
import {observer} from "mobx-react";
import {ISharedFilesBlockProps} from "./SharedFilesBlock";

const FileBlock = observer((props: ISharedFilesBlockProps) => {
    useEffect(() => {
        const $block = $(`#${props.htmlId}`),
              $blockHead = $block.find('.sb-blockHead'),
              $iframe = $block.find(`#shared-file-iframe-${props.blockObj.id}`);

        const blockOuterHeight = $block.outerHeight();
        const blockHeadOuterHeight = $blockHead.outerHeight();
        if ($iframe.length !== 0) {
            if (blockHeadOuterHeight === null) {
                $iframe.css('height', String(blockOuterHeight));
            } else if (blockOuterHeight && blockHeadOuterHeight) {
                $iframe.css('height', String(blockOuterHeight - blockHeadOuterHeight));
            }
        }
    }, []);

    let component;
    let headerIcon;
    switch (props.blockObj.fileType) {
        case "video":
            component = <video src={props.blockObj.url} controls />
            break;
        case "audio":
            component = <audio src={props.blockObj.url} controls />;
            break;
        case "image":
            component = <img src={props.blockObj.url} />;
            break;
        case "googledocs":
            component = <iframe
                src={`https://docs.google.com/viewer?srcid=${props.blockObj.gFileId}&pid=explorer&efh=false&a=v&chrome=false&embedded=true`}
                id={`shared-file-iframe-${props.blockObj.id}`}
                frameBorder="no">
            </iframe>;
            headerIcon = <div className="fab fa-fw fa-google" aria-hidden="true"
                              style={{color: 'inherit', display: 'inline-block', fontSize: '1.4em', marginRight: '0.3em'}}/>;
            break;
        default:
            component = <iframe src={`https://docs.google.com/viewer?url=${encodeURIComponent(props.blockObj.url as string)}&embedded=true`}
                                id={`shared-file-iframe-${props.blockObj.id}`}
                                frameBorder="no">
            </iframe>
    }
    const title = props.blockObj.blockModel.title ? props.blockObj.blockModel.title : "Shared Files";

    return <BlockScroller blockRef={props.blockRef}
                          blockObj={props.blockObj}
                          htmlId={props.htmlId}
                          setShowExpandButton={props.setShowExpandButton}
                          outsideGrid={props.outsideGrid}>
        <BlockHead blockType={props.blockObj.blockType} editableTitle={title} blockId={props.blockObj.id}>
            <ClickableLink href={props.blockObj?.file?.viewLink || props.blockObj.url} style={{position: 'static'}}
               title={'View ' + props.blockObj?.file?.type || "file" }>
                {headerIcon}
            </ClickableLink>
        </BlockHead>
        <div className="sb-blockContent sb-organization-color-element-font clearfix" style={{padding: 0}}>
            {component}
        </div>
    </BlockScroller>
})

export default FileBlock;
