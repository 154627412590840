import {FC, Fragment, useRef, useState, MouseEvent} from 'react';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import styles from './styles/InlineTextEditor.module.scss';
import useClickOutside from "../../hooks/useClickOutside";

const SlateEditorSimple = dynamic(() => import("../editors/SimpleEditor"));
const SlateEditor = dynamic(() => import("../editors/MarkdownEditor"));

const InlineTextEditor: FC<{
    text: string,
    handleSave?: (event?: MouseEvent) => void,
    canEdit: boolean | string | (() => void),
    isSimple?: boolean,
    wrapperClassName?: string,
    handleMouseOver?: () => void,
    handleMouseLeave?: () => void,
    toggleEditing?: (e: MouseEvent, boolean) => void,
    placeholder?: string,
    handleTextChange: (v: string) => void,
    showHoverUI?: boolean,
}> = (props) => {
    const {
        wrapperClassName = "",
        handleMouseOver = () => {},
        handleMouseLeave = () => {},
        handleSave = () => {},
        toggleEditing = () => {},
        isSimple = true,
        canEdit = false,
    } = props;

    const [editing, setEditing] = useState(false);
    const hasFocused = useRef(false);

    const onClickOutside = event => {
        if (editing) {
            setEditing(false);
            handleSave(event);
            toggleEditing(event, false);
            hasFocused.current = false;
        }
    };

    const editorRef = useClickOutside(onClickOutside);

    const _handleMouseOver = () => {
        if (canEdit && !editing) {
            handleMouseOver();
        }
    };

    const _handleMouseLeave = () => {
        if (canEdit && !editing) {
            handleMouseLeave();
        }
    };

    const handleClick = e => {
        if (canEdit && !editing) {
            setEditing(true);
            toggleEditing(e, true);
        }
    };

    const containerClassName = classNames({
        [styles.container]: true,
        [styles.containerHover]: props.showHoverUI && !editing && canEdit,
        [wrapperClassName]: wrapperClassName,
    });

    function handleTextChange(value) {
        props.handleTextChange(value);
    }

    return (
        <div ref={editorRef} className={containerClassName} onClick={handleClick}
             onMouseOver={_handleMouseOver} onMouseLeave={_handleMouseLeave}>
            {editing ? (
                <>
                    {isSimple ? <SlateEditorSimple
                        placeholder={props.placeholder}
                        handleTextChange={handleTextChange}
                        value={props.text}/> : <SlateEditor
                        placeholder={props.placeholder}
                        handleTextChange={handleTextChange}
                        value={props.text}
                    />}
                </>
            ) : <Fragment>{props.children}</Fragment>}
        </div>
    );
}

export default InlineTextEditor;
