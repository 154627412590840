import { useEffect } from 'react';
import BlockScroller from "../BlockScroller";
import BlockHead from "../_BlockHead";
import {observer} from "mobx-react";
import {ISharedFilesBlockProps} from "./SharedFilesBlock";

const OneDriveBlock = observer((props: ISharedFilesBlockProps) => {
    useEffect(() => {
        const iframe = $(`#shared-file-iframe-${props.blockObj.id}`),
            blockContent = iframe.parents('.sb-blockContent'),
            blockHead = blockContent.siblings('.sb-blockHead'),
            block = iframe.parents('.sb-block');

        const blockOuterHeight = block.outerHeight();
        const blockHeaderOuterHeight = blockHead.outerHeight();
        if (blockOuterHeight && blockHeaderOuterHeight && iframe.length !== 0) {
            const contentHeight = blockOuterHeight - blockHeaderOuterHeight;
            blockContent.css('height', contentHeight);
            iframe.css('height', contentHeight);
        }
    }, []);

    const title = props.blockObj.blockModel.title ? props.blockObj.blockModel.title : "Shared Files";

    return <BlockScroller blockRef={props.blockRef}
                          blockObj={props.blockObj}
                          htmlId={props.htmlId}
                          setShowExpandButton={props.setShowExpandButton}
                          outsideGrid={props.outsideGrid}>
        <BlockHead blockType={props.blockObj.blockType} editableTitle={title} blockId={props.blockObj.id} />
        <div className="sb-blockContent sb-organization-color-element-font clearfix" style={{padding: 0}}>
            <iframe src={props.blockObj.url}
                    id={`shared-file-iframe-${props.blockObj.id}`}
                    frameBorder="0" scrolling="no"
                    style={{padding: "1em 1em"}}>
            </iframe>
        </div>
    </BlockScroller>
})

export default OneDriveBlock;
